























declare function require(name:string): any
var MobileDetect = require('mobile-detect')
var md = new MobileDetect(window.navigator.userAgent)
export default {
  name: 'PSingleCard',
  props: {
    width: {
      type: Number,
      default: () => 250
    },
    imageAndDescription: {
      type: Object,
      // eslint-disable-next-line
      default: () => ({ 'img': 'img/logo.png', 'description': 'DefaultImgDescription' })
    }
  },
  components: {
    PSingleCardImage: () => import('./PSingleCardImage.vue')
  }
}
